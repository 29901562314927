@import '../../variables';

.loader {
    border-radius: 50%;
    border: 0.25rem solid rgba($main-color, 0.2);
    border-top-color: $main-color;
    animation: spin 500ms infinite linear;
    margin: 0 auto;

    &__top-right {
        position: fixed;
        top: 5px;
        right: 5px;
        z-index: 1000;
    }
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
